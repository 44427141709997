body{
  margin: 0;
  padding:0;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@fontsource/dm-sans/400.css";
@import "@fontsource/dm-sans/500.css";
@import "@fontsource/dm-sans/700.css";

@import "@fontsource/urbanist/400.css";
@import "@fontsource/urbanist/500.css";
@import "@fontsource/urbanist/700.css";
